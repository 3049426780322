<template>
	<div class="about">
		<hero title="Empowering individuals.<br/>Strengthening the industry" class="mb-5">
			<h4 class="text-grey-2">
				Advancing the global supply chain industry through accessible and
				<span class="moving-underline" v-in-viewport
					>affordable online learning.</span
				>
			</h4>
		</hero>
		<b-container>
			<b-row>
				<b-col md="8" class="mb-5 mb-md-0">
					<p>
						WiseTech Academy's online courses help improve the skills, knowledge
						and productive capacity of professionals working in the sector.
					</p>
					<p>
						They also provide a stepping stone for individuals looking to launch
						their career in the dynamic world of supply chain logistics.
					</p>
					<heading id="mission">Our Mission</heading>
					<p>
						At WiseTech Academy, we believe an industry is only as strong as its
						people.
					</p>
					<p>
						That's why our mission is to empower individuals to grow and prosper
						by providing easy and affordable access to high quality online
						education.
					</p>
					<p>
						And by empowering people through education, WiseTech Academy adds
						value to the global supply chain, helping create a strong and
						progressive sector for us all.
					</p>
					<heading id="story">Our Story</heading>
					<p>
						Established in 2018, WiseTech Academy is a wholly owned subsidiary
						of
						<a href="https://www.wisetechglobal.com/" target="_blank"
							>WiseTech Global</a
						>
						- a world-leading developer of cloud-based software solutions for
						domestic and international logistics companies.
					</p>
					<p>
						With over 17,000 customers in 160 countries, WiseTech Global helps these logistics companies with the efficient movement of goods
						and information around the world. Its flagship product,
						<a href="https://www.cargowise.com/" target="_blank">CargoWise</a>,
						provides an end-to-end logistics solution that forms a vital link in
						the global supply chain.
					</p>
					<p>
						The WiseTech Academy was established to offer accessible,
						affordable, online learning to the people who work in or with global
						supply chains to provide deep understanding of why and how the
						industry operates as it does, and how it could be made more
						effective and efficient.
					</p>
					<logos class="text-right pt-5 mb-4"/>
				</b-col>
				<b-col md="4">
					<train-your-team/>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>
<script>
export default {
	data() {
		return {
			x: 0
		};
	},
	mounted() {
		const _this = this;
		setInterval(() => {
			_this.x++;
		}, 500);
	},
	metaInfo: {
		title: "About page"
	}
};
</script>
